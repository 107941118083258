<template>
  <v-card class="pa-5" flat outlined>
    <v-overlay :value="!loaded" absolute opacity="0">
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="loaded">
      <v-row>
        <v-col>
          <h2 class="text-h2">
            {{ $t('Company info') }}
          </h2>
        </v-col>
        <v-col cols="auto">
          <v-tooltip left open-delay="100">
            <template v-slot:activator="{on}">
              <v-btn
                v-on="on"
                :to="{name: 'profile-company-edit'}"
                color="white"
                elevation="0"
                fab
                small
              >
                <v-icon color="grey">
                  {{ icons.edit }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Edit company info') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="text-caption text--secondary">
            {{ $t('Company name') }}
          </div>
          <div>{{ company.name }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto" class="pe-8">
          <div class="text-caption text--secondary">
            {{ $t('Company OGRN') }}
          </div>
          <div>{{ company.ogrn }}</div>
        </v-col>
        <v-col cols="auto" class="pe-8">
          <div class="text-caption text--secondary">
            {{ $t('Company INN') }}
          </div>
          <div>{{ company.inn }}</div>
        </v-col>
        <v-col cols="auto" class="pe-8">
          <div class="text-caption text--secondary">
            {{ $t('Company KPP') }}
          </div>
          <div>{{ company.kpp }}</div>
        </v-col>
      </v-row>

      <v-row v-if="company.address">
        <v-col>
          <div class="text-caption text--secondary">
            {{ $t('Company address') }}
          </div>
          <div>
            {{ company.address.zip }},
            {{ company.address.city }},
            {{ company.address.street }}
          </div>
        </v-col>
      </v-row>

      <v-row v-if="showDirectorName">
        <v-col>
          <div class="text-caption text--secondary">
            {{ $t('Director name') }}
          </div>
          <div>
            {{ company.director.nominative.lastName }}
            {{ company.director.nominative.firstName }}
            {{ company.director.nominative.middleName }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="text-caption text--secondary">
            {{ $t('Director position title') }}
          </div>
          <div>{{ company.director.nominative.position }}</div>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
import {mdiPencilOutline} from '@mdi/js'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProfileCompany',
  mixins: [error401handler],
  data () {
    return {
      icons: {
        edit: mdiPencilOutline,
      }
    }
  },
  computed: {
    ...mapState({
      company: state => state.profile.company,
      loaded: state => state.loaders.profile.status,
    }),
    showDirectorName () {
      const {company} = this
      return company.director.nominative.lastName ||
        company.director.nominative.firstName ||
        company.director.nominative.middleName
    }
  },
}
</script>
